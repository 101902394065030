/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';

const map = {
  from: 1,
  to: 5,
};
const teams = {
  from: 1,
  to: 4,
};

const events = {
  from: 81,
  to: 84,
};

const building = {
  from: 41,
  to: 49,
};

const nxp = {
  from: 61,
  to: 63,
};

const generateSrf = () => {
  let myClasses = ['map-row-col map-2-r'];
  for (let i = map.from; i <= map.to; i++) {
    myClasses.push(`map-row-col map-${i}`);
    if (i < 3) {
      for (let t = teams.from; t <= teams.to; t++) {
        myClasses.push(`map-row-col map-${i}-t${t}`);
        if (i == 2) {
          myClasses.push(`map-row-col map-2-r-t${t}`);
        }
      }
    }
  }
  return myClasses;
};
const generateBldgs = () => {
  let myClasses = [];
  for (let b = building.from; b <= building.to; b++) {
    myClasses.push(`map-building map-building-${b}`);
    myClasses.push(`map-building map-building-${b}-d`);
    if (b == 41) {
      for (let t = teams.from; t <= teams.to; t++) {
        myClasses.push(`map-building map-building-${b} base-${t}`);
      }
    }
  }
  return myClasses;
};

const generateEvts = () => {
  let myClasses = [];
  for (let e = events.from; e <= events.to; e++) {
    myClasses.push(`map-event map-event-${e}`);
  }
  return myClasses;
};

const generateNxps = () => {
  let myClasses = [];
  for (let n = nxp.from; n <= nxp.to; n++) {
    for (let t = teams.from; t <= teams.to; t++) {
      for (let d = 0; d <= 8; d += 2) {
        myClasses.push(`nxp nxp-${n}-t${t}-${d}`);
      }
    }
  }
  return myClasses;
};

const ImagePreloader = () => {
  const myClasses = useMemo(generateSrf);
  const myBldgs = useMemo(generateBldgs);
  const myEvts = useMemo(generateEvts);
  const nxps = useMemo(generateNxps);
  return (
    <div className="observe-simulation-nexus d-none">
      <div className="observe-simulation-nexus-map">
        <div className="map-row">
          {myClasses.map((className, index) => (
            <div className={className} key={`${className}-${index}`}>
              {myBldgs.map((bldg, jndex) => (
                <div className={bldg} key={`${bldg}-${jndex}`}>
                  <div />
                </div>
              ))}
              {myEvts.map((evt, endex) => (
                <div className={evt} key={`${evt}-${endex}`} />
              ))}
            </div>
          ))}
        </div>
        {nxps.map((nxp, n) => (
          <div className={nxp} key={`${nxp}-${n}`} />
        ))}
      </div>
    </div>
  );
};

export default ImagePreloader;

/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const getClassName = (field, teams) => {
  let surfaceClass = `map-surface-${field.surface}`;
  if (field.building && field.surface == 2) {
    surfaceClass = `${surfaceClass}-r`;
  }
  if (field.surface <= 2 && field.ownerTeam && teams.includes(field.ownerTeam)) {
    surfaceClass = `${surfaceClass}-t${field.ownerTeam}`;
  }
  return surfaceClass;
};
const getBldgClassName = (field) => {
  let bldgClass = `map-building`;
  const { building } = field;
  // istanbul ignore else
  if (building) {
    bldgClass = `${bldgClass}-${building.type}`;
    if (building.status == 3) {
      bldgClass = `${bldgClass}-d`;
    }
  }
  return bldgClass;
};
const getBaseClassName = (field, teams) => {
  let bldgClass = ``;
  const { building } = field;
  // istanbul ignore else
  if (building) {
    if (building.type == 41 && teams.includes(field.ownerTeam)) {
      bldgClass = `map-base-${field.ownerTeam}`;
    }
  }
  return bldgClass;
};
const maxX = 18000;
const maxY = 9000;

const getNxpPos = (nxp) => {
  let { positionX, positionY } = nxp;
  positionX = positionX % maxX;
  positionY = positionY > maxY ? maxY : positionY;
  return {
    left: `calc(${(positionX / maxX) * 100}% - calc(calc(100% / 18) / 2))`,
    top: `calc(${(positionY / maxY) * 100}% - calc(calc(100% / 9) / 2))`,
  };
};

const Map = ({ connectedTeams }) => {
  const { map: mapData, nxps: nxpData } = useSelector(({ nexus }) => nexus);
  const map = useMemo(() => mapData, [mapData]);
  const nxps = useMemo(() => nxpData, [nxpData]);

  return (
    <div className="observe-simulation-nexus-map">
      {map.map((row, i) => (
        <div className="map-row" key={`map-row-${i}`}>
          {row.map((field, j) => (
            <div
              key={`map-row-${i}-column-${j}-${getClassName(field, connectedTeams)}`}
              className="map-row-col"
            >
              <div className={`map-surface ${getClassName(field, connectedTeams)}`} />
              {field.building && <div className={`map-building  ${getBldgClassName(field)}`} />}
              {field.building && (
                <div className={`map-base ${getBaseClassName(field, connectedTeams)}`} />
              )}
              {field.event && <div className={`map-event map-event-${field.event}`} />}
            </div>
          ))}
        </div>
      ))}
      {nxps.map((nxp) => (
        <div
          className={`nxp nxp-${nxp.type}-t${nxp.ownerTeam}-${nxp.direction}`}
          style={getNxpPos(nxp)}
          key={`nexus-nxp-${nxp.id}`}
        />
      ))}
    </div>
  );
};

Map.propTypes = {
  connectedTeams: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default Map;

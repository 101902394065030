export const getEventData = (values) =>
  values.reduce((accumulator, item, time) => {
    item.events.logEvents.forEach(({ name, stopt, started }) => {
      const list = accumulator[name] || [{ name, stopt, started, start: time, end: time }];
      const prev = list[list.length - 1];
      // istanbul ignore else
      if (prev.stopt !== stopt || prev.started !== started) {
        list.push({ name, stopt, started, start: time, end: time });
      } else if ((!prev.stopt && prev.started) || prev.stopt === stopt) {
        prev.stopt = stopt;
        prev.end = time;
      }
      accumulator[name] = list;
    });
    return accumulator;
  }, {});

export const getErrorPoints = (values, data, group, cell, length) =>
  values
    .map((item, index) => {
      item = item[group][cell];
      return {
        left: (index * 100) / length,
        hasErrorPoint: item.hasErrorPoint,
        key: `error-row-item-${group}-${cell}-${index}`,
      };
    })
    .filter((item) => item.hasErrorPoint);

export const getLiftData = (values) => {
  const lifts = {
    L1: [],
    L2: [],
    L3: [],
    L4: [],
  };
  Object.keys(lifts).forEach((key) => {
    lifts[key] = values.reduce((accumulator, item, time) => {
      item = item.lift[key];
      const prevIndex = (accumulator.length || 1) - 1;
      const status = item.status;
      const isLocked = item.isLocked;
      const newObj = { start: time, end: time, status, isLocked };
      const prev = accumulator[prevIndex];
      if (prev && prev.status == status && prev.isLocked == isLocked) {
        prev.end = time;
      } else {
        accumulator.push(newObj);
      }
      return accumulator;
    }, []);
  });
  return lifts;
};

export const getChartData = (values, data, groupKey, cellKey) =>
  values.map((item, key) => {
    const value =
      typeof item[groupKey][cellKey] === 'number'
        ? item[groupKey][cellKey]
        : item[groupKey][cellKey].value;
    return {
      name: key * 1,
      y: value,
    };
  });

export const solarReceptorCrashChart = (values) =>
  values.reduce((accumulator, item, time) => {
    item = item.position.solarPosition;
    const prevIndex = (accumulator.length || 1) - 1;
    const prev = accumulator[prevIndex];
    const newObj = { start: time, end: time, filled: item.subCellKey };
    if (prev && prev.filled === item.subCellKey) {
      prev.end = time;
    } else {
      accumulator.push(newObj);
    }
    return accumulator;
  }, []);

export const alarmChart = (values) =>
  values.reduce((accumulator, item, time) => {
    item = item.energy.alarm;
    const prevIndex = (accumulator.length || 1) - 1;
    const prev = accumulator[prevIndex];
    const newObj = { start: time, end: time, yellow: item.yellow, red: item.red };
    if (prev && prev.yellow === item.yellow && prev.red === item.red) {
      prev.end = time;
    } else {
      accumulator.push(newObj);
    }
    return accumulator;
  }, []);

export const booleanCharts = (values, data, groupKey, cellKey) =>
  values.reduce((accumulator, item, time) => {
    if (!item[groupKey]) {
      item[groupKey] = {
        [cellKey]: false,
      };
    }
    item = item[groupKey][cellKey];
    const prevIndex = (accumulator.length || 1) - 1;
    const prev = accumulator[prevIndex];
    const newObj = { start: time, end: time, filled: item };
    if (prev && prev.filled === item) {
      prev.end = time;
    } else {
      accumulator.push(newObj);
    }
    return accumulator;
  }, []);

export const prepareInfoJobList = (keys, data, type) => {
  const list = {};
  keys.map((key) => {
    const item = data[key];
    const typeList = item[type];
    /* istanbul ignore else */
    if (typeList) {
      for (let role in typeList) {
        for (let name in typeList[role]) {
          if (!typeList[role][name].log) {
            continue;
          }
          /* istanbul ignore else */
          if (!list[name]) {
            list[name] = {};
          }
          /* istanbul ignore else */
          if (!list[name][role]) {
            list[name][role] = {};
          }
          list[name][role][key] = typeList[role][name];
          list[name].title = typeList[role][name].name;
          list[name].log = typeList[role][name].log;
        }
      }
    }
  });
  return list;
};

export const extractIfoJobData = (values, type) => {
  const extracted = {};
  values.forEach((row, key) => {
    row &&
      row[type] &&
      Object.values(row[type]).forEach((infoList, role) => {
        const roleList = extracted[role] || {};
        Object.keys(infoList).forEach((infoName) => {
          const record = roleList[infoName] || [];
          infoList[infoName].time = key;
          record.push(infoList[infoName]);
          roleList[infoName] = record;
        });
        extracted[role] = roleList;
      });
  });
  return extracted;
};

export const formatNexus = (nexus) => {
  const teamData = nexus;
  const values = Object.values(teamData);
  return {
    ...nexus,
    discoveries: {
      probability: getChartData(values, teamData, 'discoveries', 'probability'),
      surface: getChartData(values, teamData, 'discoveries', 'surface'),
    },
    eventData: getEventData(Object.values(nexus), nexus),
  };
};

export const formatTimeLine = (timeLine) => {
  const data = {};
  Object.keys(timeLine).forEach((team) => {
    const teamData = timeLine[team];
    const values = Object.values(teamData);
    const keys = Object.keys(teamData);
    const length = keys.length;
    data[team] = {
      length,
      keys,
      eventData: getEventData(values, teamData),
      liftData: getLiftData(values, teamData),
      infoJob: {
        infos: prepareInfoJobList(keys, teamData, 'infos'),
        jobs: prepareInfoJobList(keys, teamData, 'jobs'),
        extractedInfos: extractIfoJobData(values, 'infos'),
        extractedJobs: extractIfoJobData(values, 'jobs'),
      },
      errorPoints: {
        position: {
          speed: getErrorPoints(values, teamData, 'position', 'speed', length),
          solarPosition: getErrorPoints(values, teamData, 'position', 'solarPosition', length),
        },
        lift: {
          L1: getErrorPoints(values, teamData, 'lift', 'L1', length),
          L2: getErrorPoints(values, teamData, 'lift', 'L2', length),
          L3: getErrorPoints(values, teamData, 'lift', 'L3', length),
          L4: getErrorPoints(values, teamData, 'lift', 'L4', length),
        },
        energy: {
          accusInMain: getErrorPoints(values, teamData, 'energy', 'accusInMain', length),
        },
      },
      chartData: {
        position: {
          speed: getChartData(values, teamData, 'position', 'speed'),
          solarPosition: getChartData(values, teamData, 'position', 'solarPosition'),
          solarReceptorCrash: solarReceptorCrashChart(values, teamData),
        },
        energy: {
          solarReceptor: getChartData(values, teamData, 'energy', 'solarReceptor'),
          generator: getChartData(values, teamData, 'energy', 'generator'),
          alarm: alarmChart(values, teamData),
          energyConsumptionHigh: booleanCharts(values, teamData, 'energy', 'energyConsumptionHigh'),
          warnings: booleanCharts(values, teamData, 'energy', 'warnings'),
        },
        defects: {
          main: booleanCharts(values, teamData, 'defects', 'main'),
          support: booleanCharts(values, teamData, 'defects', 'support'),
          commander: booleanCharts(values, teamData, 'defects', 'commander'),
        },
      },
    };
  });
  return data;
};
